import React from 'react';
import './WorkoutsMain.css';

export default function WorkoutsMain() {
    return (
        <div className="WorkoutsMain">
            <main className="container">
                <div className="container-content">Workout Routine</div>
                <div className="container-content">Workout Routine</div>
                <div className="container-content">Workout Routine</div>
                <div className="container-content">Workout Routine</div>
                <div className="container-content">Workout Routine</div>
                <div className="container-content">Workout Routine</div>
            </main>
        </div>
    )
}
